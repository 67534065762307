import React from "react";
import i18n from "i18next";
import { Button } from "../../common";
import "./style.scss";
import { useTranslation, Trans } from "react-i18next";

const MypaLinkComponent = (props) => {
  const {
    data: { unit },
    scormDataId,
    origin,
  } = props;
  // const hostname = window.location.hostname;

  const copyAll = (elId) => {
    const el = document.getElementById(elId);
    const range = document.createRange();
    range.selectNode(el);
    window.getSelection().addRange(range);

    try {
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    } catch (err) {
      // console.log("Oops, unable to copy");
    }
  };

  const getDomain = () => {
    try {
      const url = new URL(origin);
      return url.hostname;
    } catch {
      return origin;
    }
  };

  const getMypaLink = () => {
    const link = process.env.REACT_APP_MYPA_JOIN_LINK;
    return (
      <>
        <Trans i18nKey="linkToMYpa1">
          As a Pineapple Academy student, you have the ability to link your
          Learning Management System account to your PIÑA account, allowing your
          student records to flow directly to your "My Activity" section of
          PIÑA. To get set up, simply click
        </Trans>
        {""}
        <a
          className="copyLink"
          href={link}
          style={{ margin: "5px" }}
          target="_blank"
        >
          <Trans i18nKey="linkToMYpa2"> THIS LINK </Trans>
        </a>
        <i class="fas fa-link"></i>{" "}
        <Trans i18nKey="linkToMYpa3">
          and use the information below along with an access code provided by
          your supervisor to complete the registration.
        </Trans>
      </>
    );
  };
  const { t } = useTranslation();

  return (
    <div className="MypaContainer">
      <div
        className="title"
        dangerouslySetInnerHTML={{
          __html: unit.title[i18n.language],
        }}
      ></div>
      <br />
      <div>{getMypaLink()}</div>
      <br />
      <div>
        <form className="form">
          <div className="form-input">
            <div for="lmsDomain">
              <strong>LMS Domain:</strong>
            </div>
            <a
              href="javascript:void(0)"
              className="copyLink"
              onClick={() => copyAll("lmsDomain")}
            >
              <span id="lmsDomain">{getDomain()}</span>
              <i class="far fa-copy shown"></i>
              <i class="fas fa-copy hidden"></i>
            </a>
          </div>
          <br />
          <div className="form-input">
            <div for="learnerId">
              <strong>Learner Id:</strong>
            </div>
            <a
              href="javascript:void(0)"
              className="copyLink"
              onClick={() => copyAll("learnerId")}
            >
              <span id="learnerId">{scormDataId}</span>
              <i class="far fa-copy shown"></i>
              <i class="fas fa-copy hidden"></i>
            </a>
          </div>
        </form>
      </div>
      <br />
      <Button
        variant="primary"
        onClick={() => props.updateCurrentUnit(props.data.id)}
      >
        {t("Skip and Continue")}
      </Button>
    </div>
  );
};

export default MypaLinkComponent;
