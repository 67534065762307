import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  ContentNotAvailable,
  CourseUndefined,
  LicenseIssue,
  LmsApiNotFound,
  CourseFinished,
  SessionTimeout,
  StudentNotExists,
  StudentNotActive,
} from "../components/errorComponent";
import NotFoundComponent from "../components/notFoundComponent";
import SentryDebugComponent from "../components/sentryDebugComponent";
import { ContextDataComponentAndProvider } from "../context/ContextData";

const RouterManager = (props) => {
  const [boostrapOrigin, setBootstrapOrigin] = useState("*");

  useEffect(() => {
    sendHandshake();
  }, []);
  
  const [scormData, setScormData] = useState({
    name: "---",
  });

  const initializeSession = () => {
    window.parent.postMessage(
      {
        event: "pa_session_init",
        data: {
          handle: setTimeout(function () {
            alert("do something... session never initialized...");
          }, 4000),
        },
      },
      boostrapOrigin
    );
  };

  const configureSession = (e) => {
    setScormData(e.data);
  };

  const dispatchCallback = (e) => {
    if (e.origin.indexOf("vimeo.com") > -1) return;

    var key = e.message ? "message" : "data";
    var d = e[key];
    if (!d.event) return;
    if (!d.data) return;
    if (d.data.handle) clearTimeout(d.data.handle);

    switch (d.event) {
      case "pa_session_init":
        configureSession(d);
        break;
      default:
        return;
    }
  };

  const validateHandshake = (e) => {
    if (e.origin.indexOf("vimeo.com") > -1) return;

    var key = e.message ? "message" : "data";
    var d = e[key];
    if (d.event && d.event === "pa_xof_reg") {
      if (d.data.handle) {
        clearTimeout(d.data.handle);
      }
      console.log("setting boostrapOrigin:", e.origin);
      setBootstrapOrigin(e.origin);
      initializeSession();

      window.removeEventListener("message", validateHandshake);
      window.addEventListener("message", dispatchCallback);
      return;
    }
  };

  const sendHandshake = () => {
    window.addEventListener("message", validateHandshake);
    var xof_init = {
      event: "pa_xof_init",
      data: {
        code: "abc1234", //this.session_code,
        handle: setTimeout(function () {
          console.error('Communication with the LMS has not been confirmed.",');
        }, 4000),
      },
    };

    window.parent.postMessage(xof_init, "*");
  };

  const markAsComplete = () => {
    const data = {
      event: "pa_lms_set",
      data: {
        model: "cmi.core.lesson_status",
        value: "completed",
        commit: true,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const apiGatewayWelcome = (sub) => {
    const data = {
      event: "pa_setvalue",
      data: {
        model: "core.student.person",
        value: sub,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const markUnitStartedOrCompleted = (key, value) => {
    const data = {
      event: "pa_setvalue",
      data: {
        model: `core.course.${key}`,
        value,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const markLessonLocation = (step) => {
    const data = {
      event: "pa_lms_set",
      data: {
        model: "cmi.core.lesson_location",
        value: step,
        commit: true,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const markRating = (rating, comment) => {
    const data = {
      event: "pa_setvalue",
      data: {
        model: "core.course.rating",
        value: JSON.stringify({ r: rating, c: comment }),
        commit: true,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
    if (version === "3") {
      // TODO: post to API
      fetch(`/api/v1.0/pacman/rating/?token=${authToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rating,
          comment,
        }),
      });
    }
  };

  const markVideoProgress = (videoId, duration) => {
    const data = {
      event: "pa_setvalue",
      data: {
        model: "core.course.video_progress",
        value: JSON.stringify({ vid: videoId, dur: duration }),
        commit: true,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const msToCMIDuration = (n) => {
    n = !n || n < 0 ? 0 : n; //default value and force positive duration
    var hms = "";
    var dtm = new Date();
    dtm.setTime(n);
    var h = "0" + Math.floor(n / 3600000);
    var m = "0" + dtm.getMinutes();
    var s = "0" + dtm.getSeconds();
    hms = h.substr(h.length - 2) + ":" + m.substr(m.length - 2) + ":";
    hms += s.substr(s.length - 2);
    return hms;
  };

  const markInteraction = (input) => {
    const data = {
      event: "pa_lms_set_interaction",
      data: {
        id: `${input.iid ?? input.id}^${input.attempt}`,
        type: "choice",
        response: input.answer,
        result: input.isCorrect ? "correct" : "wrong",
        time: `${input.startedOn.getHours()}:${input.startedOn.getMinutes()}:${input.startedOn.getSeconds()}`,
        latency: msToCMIDuration(new Date() - input.startedOn),
        commit: true,
      },
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const markFinish = (input) => {
    console.log("FINISHING");
    const data = {
      event: "pa_lms_finish",
      data: {},
    };
    window.parent.postMessage(data, boostrapOrigin);
  };

  const u = new URL(window.location);
  const searchParams = u.searchParams;

  const packageId = searchParams.get("pkg");
  const version = searchParams.get("v");
  const authToken = searchParams.get("auth");
  let courseUrl = searchParams.get("url")?.replace("-", "+").replace("_", "/");

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/404" />} />
        <Route path="/error/lms-api-not-found" element={<LmsApiNotFound />} />
        <Route path="/error/expired" element={<SessionTimeout />} />
        <Route
          path="/error/package-id-not-found"
          element={<CourseUndefined />}
        />
        <Route
          path="/error/content-not-available"
          element={<ContentNotAvailable />}
        />
        <Route path="/error/license-issue" element={<LicenseIssue />} />
        <Route
          path="/error/student-not-exists"
          element={<StudentNotExists />}
        />
        <Route
          path="/error/student-not-active"
          element={<StudentNotActive />}
        />
        <Route path="/course-finished" element={<CourseFinished />} />
        <Route
          path="/course"
          element={
            <ContextDataComponentAndProvider
              version={version}
              courseUrl={courseUrl}
              packageId={packageId}
              scormData={scormData}
              markAsComplete={markAsComplete}
              markLessonLocation={markLessonLocation}
              markUnitStartedOrCompleted={markUnitStartedOrCompleted}
              apiGatewayWelcome={apiGatewayWelcome}
              markInteraction={markInteraction}
              markFinish={markFinish}
              markVideoProgress={markVideoProgress}
              markRating={markRating}
              auth={authToken}
              origin={boostrapOrigin}
            />
          }
        />
        <Route exact path="/404" element={<NotFoundComponent />} />
        <Route exact path="/sentry-debug" element={<SentryDebugComponent />} />
      </Routes>
    </Router>
  );
};

export default RouterManager;
