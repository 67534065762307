import React from "react";
import pineappleLogo from "../../assets/pineapple-logo.png";
import "./style.scss";
const NotFoundComponent = () => {
  return (
    <div className="container">
      <div className="bg"></div>
      <div className="notfound">
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <div className="notfound-404">
          <h3 style={{ color: "#fff" }}>Oops! Not Found </h3>
          <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
        </div>
        <div className="boton">
          <div className="btn">Sorry, the page you visited does not exist</div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundComponent;
