import React, { useEffect, useState } from "react";
import { Button } from "../../common";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./style.scss";
import Container from "@mui/material/Container";
import { StyledEngineProvider } from "@mui/material/styles";
import { urlForVideoUnit } from "../utils";

const VideoComponent = (props) => {
  const {
    data: {
      unit: { completed },
    },
    updateCurrentUnit,
    jumpToNextUnit,
    isShownFromQuestionComponent,
    questionComponentAction,
    video_link_id,
    schemaVersion,
    videoAlias,
    studentId
  } = props;
  
  const [isWatched, setIsWatched] = useState(false);
  const [isDisabledToContinue, setIsDisabledToContinue] = useState(true);


useEffect(()=>{
    const childWindow = document.getElementById('embed-player')?.contentWindow;
    if (childWindow) {
        window.addEventListener('message', message => {
            const { type, outro } = message.data;
            if(type == 'timeupdate'){
              // console.log('outro', outro, isDisabledToContinue)
              if(isDisabledToContinue && outro){
                setIsDisabledToContinue(false);
              }
            } else if (type == 'ended') {
              setIsDisabledToContinue(false);
              setIsWatched(true);
            }
            // console.log(message.data);
        });
    }
  },[]);

  const { t } = useTranslation();

  return (
    <StyledEngineProvider injectFirst>
      <div className="videoContainer">
        <Container maxWidth="md">
          <div className="videoWrapper">
            <iframe src={`${urlForVideoUnit(video_link_id, props.data.unit, i18n.language, completed, schemaVersion, videoAlias, studentId)}`} frameBorder="0" allowFullScreen id="embed-player"></iframe>
          </div>
        </Container>
        <div className="btns">
          {!isShownFromQuestionComponent ? (
            <>
              {completed ? (
                <Button
                  variant="primary"
                  onClick={() => jumpToNextUnit(props.data.id)}
                >
                  {t("Continue")}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  disabled={isDisabledToContinue}
                  onClick={() => {
                    updateCurrentUnit(props.data.id);
                  }}
                >
                  {t("Continue")}
                </Button>
              )}
            </>
          ) : (
            <Button
              variant="primary"
              disabled={!isWatched}
              onClick={() => questionComponentAction()}
            >
              {t("Return to Question")}
            </Button>
          )}
        </div>
      </div>
    </StyledEngineProvider>
  );
};

export default VideoComponent;
