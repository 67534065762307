import React from "react";
import { Button } from "../../common";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import Alert from "@mui/material/Alert";
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import "./style.scss";
const RatingComponent = (props) => {
    const {
        updateCurrentUnit,
        jumpToNextUnit,
        markRating,
        data: {
            unit: {
                completed }
        }
    } = props;
    const [ratingError, setRatingError] = React.useState(false);
    const [rating, setRating] = React.useState(0);
    const [comment, setComment] = React.useState('');
    const { t } = useTranslation();
    return (
        <div className='textContainer'>
            <Stack spacing={2} direction="column"
                justifyContent="center"
                alignItems="center">
                    
            <div><strong>Please take a moment to rate this course.</strong> <br/>Your feedback is very important to us to help curate future training.</div>
                {ratingError && <Alert severity="error">Rating is required.  If you don't want to submit a rating click "No, thanks" instead.</Alert>}    
                <Rating
                    name="simple-controlled"
                    size={"large"}
                    precision={0.5}
                    value={rating}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                />
                <TextField
                    id="outlined-multiline-static"
                    label="(optional) Comment"
                    multiline
                    rows={4}
                    style={{ width: "80%" }}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Stack>
            <div className='btns'>
                {completed ?
                <>
                 <Button
                        variant='contained'
                        onClick={() => jumpToNextUnit(props.data.id)}>
                        {t('No, thanks')}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => jumpToNextUnit(props.data.id)}>
                        {t('Continue')}
                    </Button>
                    </>
                    :
                    <>
                     <Button
                        variant='contained'
                        onClick={() => updateCurrentUnit(props.data.id)}>
                        {t('No, thanks')}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            if (rating) {
                                markRating(rating, comment);
                                updateCurrentUnit(props.data.id);
                                setRatingError(false);
                            } else {
                                setRatingError(true);
                            }
                        }
                        }>
                        {t('Continue')}
                    </Button>
                    </>
                }
            </div>
        </div>
    );
};

export default RatingComponent;
