import React, { createContext, useState, useEffect } from "react";
import { Navigate } from "react-router";
import CourseComponent from "../components/courseComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
export const dataContext = createContext();

export function ContextDataComponentAndProvider(props) {
  const [redirectTo404, setRedirectTo404] = useState(false);
  const [data, setData] = useState(null);
  const [showData, setShowData] = useState(false);

  const getCourse = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    /* live */

    let url = atob(props.courseUrl);
    if(props.version === "3"){
      url += `?token=${props.auth}`
    }
    console.log(`config: ${url}`);

    const response = await fetch(url, requestOptions);
    try {
      const result = await response.json();
      setData(result);
      setShowData(true);
    } catch (error) {
      setRedirectTo404(true);
      throw new Error(`${url}, ${error}`);
    }

    /* /live */

    /* test */

    // setData({
    //   video_link_id: 'b6atyxqquf',
    //   languages: ["EN"],
    //   legacy_id: "10006",
    //   number: "JN3XRHBU",
    //   revision: 1,
    //   require_voucher: false,
    //   title: {
    //     EN: "Knife Cuts: Celery",
    //   },
    //   units: [
    //     {
    //       approx_length: "3m",
    //       hide_from_outline: false,
    //       intro: "0:00:08",
    //       length: "0:02:43",
    //       on_start: ["course_JN3XRHBU"],
    //       outro: "0:00:20",
    //       subtitles: [],
    //       title: {
    //         EN: "Knife Cuts: Celery",
    //       },
    //       type: "video",
    //       video: {
    //         EN: {
    //           id: "zx8o5hwetrtrw64o",
    //           tracks: [
    //             {
    //               default: false,
    //               kind: "subtitles",
    //               label: "Spanish",
    //               src: "https://media.pineappleacademy.com/3wdscnc4e82c2sbh/subs/es.vtt",
    //               srcLang: "es",
    //             },
    //             {
    //               default: false,
    //               kind: "subtitles",
    //               label: "English",
    //               src: "https://media.pineappleacademy.com/3wdscnc4e82c2sbh/subs/en.vtt",
    //               srcLang: "en",
    //             },
    //           ],
    //           url: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         },
    //       },
    //     },
    //     {
    //       answers: {
    //         A: {
    //           EN: "Covered storage container on counter",
    //         },
    //         B: {
    //           EN: "Submerged in cold water in a dated and labeled covered container",
    //         },
    //         C: {
    //           EN: "They should not be stored once cut",
    //         },
    //         D: {
    //           EN: "Leave in the pot they will be cooked in",
    //         },
    //       },
    //       correct_answer: "B",
    //       hide_from_outline: true,
    //       incorrect: {
    //         start_on: {
    //           EN: "0:01:54",
    //         },
    //         stop_on: {
    //           EN: "0:02:06",
    //         },
    //         video: {
    //           EN: {
    //             id: "zx8o5hwetrtrw64o",
    //             tracks: [
    //               {
    //                 default: false,
    //                 kind: "subtitles",
    //                 label: "Spanish",
    //                 src: "https://media.pineappleacademy.com/3wdscnc4e82c2sbh/subs/es.vtt",
    //                 srcLang: "es",
    //               },
    //               {
    //                 default: false,
    //                 kind: "subtitles",
    //                 label: "English",
    //                 src: "https://media.pineappleacademy.com/3wdscnc4e82c2sbh/subs/en.vtt",
    //                 srcLang: "en",
    //               },
    //             ],
    //             url: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //           },
    //         },
    //       },
    //       on_complete: ["course_JN3XRHBU"],
    //       text: {
    //         EN: "What is best practice for storing cut celery and carrots overnight?",
    //       },
    //       title: {
    //         EN: "",
    //       },
    //       type: "question",
    //     },
    //     {
    //       hide_from_outline: true,
    //       title: {
    //           EN: "Rate our content"
    //       },
    //       type: "rating",
    //     },
    //     {
    //       type: "finish",
    //     },
    //   ],
    // });
    // setShowData(true);

    /* /test */
  };

  useEffect(() => {
    getCourse();
  }, []);

  const renderContent = () => {
    if (!showData)
      return (
        <div
          className="loaderData"
          style={{
            flexDirection: "column",
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
          <div className="text">Loading Data</div>
        </div>
      );
    if (data) {
      return <CourseComponent {...props} data={data} />
    }
    return <></>
  };

  return (
    <dataContext.Provider value={{}}>
      {redirectTo404 && <Navigate to="/404" />}
      {renderContent()}
    </dataContext.Provider>
  );
}
