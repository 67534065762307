import React from "react";
import { Button } from "../../common";
import { useTranslation } from "react-i18next";
import "./style.scss";
const TextComponent = (props) => {
    const { updateCurrentUnit, jumpToNextUnit, data: { unit: { completed } } } = props;
    const { t } = useTranslation();
    return (
        <div className='textContainer'>
            <div
                className='title'
                dangerouslySetInnerHTML={{
                    __html: props.data.unit.content,
                }}></div>
            <div className='btns'>
                { completed ?
                    <Button
                        variant='primary'                                    
                        onClick={() => jumpToNextUnit(props.data.id)}>                                
                        {t('Continue')}
                    </Button> 
                    :
                    <Button
                        variant='primary'
                        onClick={() => updateCurrentUnit(props.data.id)}>
                        {t('Continue')}
                    </Button>        
                }        
            </div>
        </div>
    );
};

export default TextComponent;
