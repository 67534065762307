import React from "react";
import "./style.scss";
import pineappleLogo from "../../assets/pineapple-logo.png";
export const LmsApiNotFound = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>
          Woops, it looks like there may be a connectivity issue. The course is
          unable to locate the LMS.
        </p>
        <p>
          Please try again or contact support:{" "}
          <a href="mailto:support@pineappleacademy.com">
            support@pineappleacademy.com
          </a>
        </p>
      </div>
    </div>
  );
};
export const LicenseIssue = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>
          Woops, it looks like there may be a problem with this course’s
          license.
        </p>
        <p>
          Please ensure this course has been purchased and the active user count
          is not past it's limit and try again or contact support:{" "}
          <a href="mailto:support@pineappleacademy.com">
            support@pineappleacademy.com
          </a>
        </p>
      </div>
    </div>
  );
};

export const StudentNotExists = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>Woops, it looks like this student was not found.</p>
        <p>
          Please try again or contact support:{" "}
          <a href="mailto:support@pineappleacademy.com">
            support@pineappleacademy.com
          </a>
        </p>
      </div>
    </div>
  );
};

export const StudentNotActive = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>Woops, it looks like this student is not active.</p>
        <p>
          Please try again or contact support:{" "}
          <a href="mailto:support@pineappleacademy.com">
            support@pineappleacademy.com
          </a>
        </p>
      </div>
    </div>
  );
};

export const ContentNotAvailable = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>Woops, it looks like there may be a problem with this course.</p>
        <p>
          Please try again or contact support:{" "}
          <a href="mailto:support@pineappleacademy.com">
            support@pineappleacademy.com
          </a>
        </p>
      </div>
    </div>
  );
};
export const CourseUndefined = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <div className="bg"></div>
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>Woops, it looks like there may be a problem with this course.</p>
        <p>
          Please try again or contact support:{" "}
          <a href="mailto:support@pineappleacademy.com">
            support@pineappleacademy.com
          </a>
        </p>
      </div>
    </div>
  );
};
export const CourseFinished = () => {
  const [time, setTime] = React.useState(5);

  React.useEffect(() => {
    let interval = null;
    interval = setInterval(function () {
      setTime((prevState) => {
        if (prevState <= 0) {
          clearInterval(interval);
          if (window.parent) {
            window.parent.close();
          }else{
            window.close();
          }
          return 0;
        }
        return prevState - 1;
      });
    }, 750);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <div className="bg"></div>
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>Congratulations you have finished this course successfully.</p>
        <p>
          Nothing more to see here, you can close this window. (attempt to close
          in {time})
        </p>
      </div>
    </div>
  );
};
export const SessionTimeout = () => {
  return (
    <div className="errorContainer">
      <div className="bg"></div>
      <div className="content">
        <div className="bg"></div>
        <img src={pineappleLogo} srcSet={pineappleLogo} alt="" />
        <p>Your session has timed out.</p>
        <p>To continue, please close and relaunch the course.</p>
      </div>
    </div>
  );
};
