import React, { useState, useEffect } from "react";
import "./App.scss";
import RouterManager from "./Router/RouterContext.jsx";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setAuthStatus] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    // async function fetchSession() {
    //   try {
    //     const session = await Auth.currentSession();
    //     setAuthStatus(true);
    //     const sub = session.accessToken.payload.sub;
    //     console.log(`session ${session}`);
    //     console.log(`sub ${sub}`);
    //     const userTmp = await Auth.currentAuthenticatedUser();
    //     setUser(userTmp);
    //   } catch (error) {
    //     if (error !== "No current user") {
    //       console.log(error);
    //     }
    //   }
    //   setIsAuthenticating(false);
    // }
    // if (isAuthenticating) {
    //   Auth.currentSession()
    //     .then((session) => {
    //       setAuthStatus(true);
    //       const sub = session.accessToken.payload.sub;
    //       console.log(`session ${session}`);
    //       console.log(`sub ${sub}`);
    //       Auth.currentAuthenticatedUser().then((userTmp) => {
    //         setUser(userTmp);
    //       });
    //     })
    //     .catch((error) => {
    //       if (error !== "No current user") {
    //         console.log(error);
    //       }
    //     })
    //     .finally(() => {
    //       setIsAuthenticating(false);
    //     });
    // }
  }, [isAuthenticating, isAuthenticated, user]);

  const authProps = {
    isAuthenticating,
    isAuthenticated,
    user,
    setAuthStatus,
    setUser,
    setIsAuthenticating,
  };

  return (
    <div className="App">
      <RouterManager auth={authProps} />
    </div>
  );
}

export default App;
