import React, { useEffect } from "react";
import axios from "axios";
import "./style.scss";
import { useState } from "react";
// import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
import { Button } from "../../common";
import { useTranslation } from "react-i18next";
// import i18n from "i18next";
// import WarningIcon from "@mui/icons-material/Warning";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const VoucherComponent = (props) => {
  // const {
  //   updateCurrentUnit,
  //   jumpToNextUnit,
  //   markInteraction,
  //   data: {
  //     unit: { completed },
  //   },
  // } = props;
  const voucherApi = process.env.REACT_APP_MYPA_VOUCHER_API;

  const [state, setState] = useState({
    voucher_id: "",
    error: false,
    error_message: "",
    voucher_data: undefined,
    validator_form: {},
    form_errors: {},
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (state.voucher_data) {
      props.setVoucher(state.voucher_data);
    }
  }, [state]);

  const voucherChanged = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        voucher_id: e.target.value,
        error: false,
        error_message: "",
      };
    });
  };

  const submitValidatorForm = (e) => {
    e.preventDefault();
    const payload = {
      ...state.validator_form,
      voucher_id: state.voucher_id,
      package_id: props.packageId,
      student_id: props.studentId,
    };
    console.log(payload);
    const email_error = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
      state.validator_form.email
    )
      ? ""
      : "Email is not valid.";
    if (email_error) {
      setState((prevState) => {
        const newValue = { ...prevState.field_errors, email: email_error };
        return { ...prevState, field_errors: newValue };
      });
      return;
    }
    axios
      .post(`${voucherApi}claim/`, payload)
      .then((resp) => {
        setState((prevState) => {
          return {
            ...prevState,
            error: false,
            error_message: "",
            voucher_data: resp.data.data,
          };
        });
      })
      .catch((err) => {
        setState((prevState) => {
          return {
            ...prevState,
            error: true,
            error_message: err.message,
          };
        });
      });
  };

  const afsValidatorFormChanged = (key, value) => {
    setState((prevState) => {
      const newValue = { ...prevState.validator_form, [key]: value };
      return { ...prevState, validator_form: newValue };
    });
  };

  const validateVoucher = (e) => {
    e.preventDefault();
    axios
      .post(`${voucherApi}validate/`, {
        voucher_id: state.voucher_id,
        package_id: props.packageId,
      })
      .then((resp) => {
        setState((prevState) => {
          return {
            ...prevState,
            error: false,
            error_message: "",
            voucher_data: resp.data.data,
          };
        });
      })
      .catch((err) => {
        setState((prevState) => {
          return {
            ...prevState,
            error: true,
            error_message: err.message,
          };
        });
      });
  };

  return (
    <>
      {!state.voucher_data && (
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontSize: { md: "20px" } }}
              style={{ color: "#fff" }}
            >
              Enter a voucher code below to unlock the course. If you have not
              yet received a voucher code, please ask to your manager.
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Grid item xs={12}>
              <Container
                sx={{ backgroundColor: "white", padding: 2, borderRadius: 3 }}
              >
                <TextField
                  id="outlined-basic"
                  label="Voucher Code"
                  variant="outlined"
                  fullWidth
                  onChange={voucherChanged}
                  error={state.error}
                  helperText={state.error_message}
                />
              </Container>
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row" justifyContent="center">
            <Grid item>
              <Button variant="primary" onClick={validateVoucher}>
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {state.voucher_data?.validator === "afs" && (
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontSize: { md: "20px" } }}
              style={{ color: "#fff" }}
            >
              Your personal information is requried to claim this exam voucher.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: "white", padding: 2, borderRadius: 3 }}
          >
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    afsValidatorFormChanged("email", e.target.value);
                  }}
                  error={state.field_errors?.email}
                  helperText={state.field_errors?.email}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    afsValidatorFormChanged("first_name", e.target.value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    afsValidatorFormChanged("last_name", e.target.value);
                  }}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          {state.error && (
            <Typography
            style={{ color: "#FF0000" }}
            >
              ERROR: Voucher submission failed, please try again.
            </Typography>
          )}
          <Grid container item xs={12} direction="row" justifyContent="center">
            <Grid item>
              <Button variant="primary" onClick={submitValidatorForm}>
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default VoucherComponent;
