import React from "react";
import "./style.scss";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import VideoComponent from "../videoComponent";
import { Button } from "../../common";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const QuestionComponent = (props) => {
  const {
    updateCurrentUnit,
    jumpToNextUnit,
    markInteraction,
    data: {
      unit: { completed },
    },
    video_link_id,
    schemaVersion,
    videoAlias,
    studentId
  } = props;
  const [isDisabledToContinue, setIsDisabledToContinue] = useState(true);
  const [value, setValue] = useState({});
  const [attempt, setAttempt] = useState(1);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const startedOn = new Date();

  const allowIncorect = props.data.unit.allow_incorrect ?? false;

  useEffect(() => {
    if (completed) {
      setValue(props.data.unit.correct_answer);
    }
  }, [completed]);

  function validateAnswer() {
    const isCorrect = value === props.data.unit.correct_answer;
    const data = {
      id: props.data.unit.id,
      iid: props.data.unit.iid,
      attempt,
      answer: value,
      isCorrect,
      startedOn,
    };
    markInteraction(data);

    if (allowIncorect){
      successEventQuestion();
      return;
    }

    if (isCorrect) {
      setSuccess(true);
      return;
    }
    setError(true);
    setAttempt(attempt + 1);
  }

  function successEventQuestion(event, error = false) {
    updateCurrentUnit(props.data.id, error);
  }

  function showVideoError() {
    if (error && showVideo) {
      setError(false);
      setValue({});
      setIsDisabledToContinue(true);
    }
    setShowVideo(!!!showVideo);
  }



  const answers = props.data.unit.answers;
  const answersKeys = Object.keys(answers);
  const { t } = useTranslation();

  return (
    <div className="questionContainer">
      {!success && !error && (
        <div className="questionList">
          <div className="title">Question:</div>
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: props.data.unit.text[i18n.language],
            }}
          ></div>

          {answersKeys.map((answerKey, idx) => {
            return (
              <div key={idx} className="questionItem">
                <div
                  className={
                    value === answerKey ? "activeLabelName " : "labelName"
                  }
                >
                  {answerKey}
                </div>
                <div
                  className={
                    value === answerKey ? "question active " : "question"
                  }
                  onClick={() => {
                    setValue(answerKey);
                    setIsDisabledToContinue(false);
                  }}
                >
                  <div className="text">
                    {answers[answerKey][i18n.language]}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="btns">
            {completed ? (
              <Button
                variant="primary"
                onClick={() => jumpToNextUnit(props.data.id)}
              >
                {t("Continue")}
              </Button>
            ) : (
              <Button
                className="btn"
                variant="primary"
                disabled={isDisabledToContinue}
                onClick={() => validateAnswer()}
              >
                {t("Submit")}
              </Button>
            )}
          </div>
        </div>
      )}
      {success && (
        <div className="successComponent">
          <Stack
            spacing={2}
            direction="column"
            alignContent="center"
            sx={{
              textAlign: "center",
            }}
          >
            <div>
              <CheckCircleIcon htmlColor="#8eb53e" sx={{ fontSize: 60 }} />
            </div>
            <div style={{ fontSize: "1.5em" }}>{t("Success")}</div>
            <div>{t("Good job, that is correct!")}</div>

            <div className="btns">
              <div
                className="btn"
                key="accept"
                onClick={() => {
                  successEventQuestion();
                }}
              >
                {t("Next")}
              </div>
            </div>
          </Stack>
        </div>
      )}
      {error && (
        <div className="errorComponent">
          {!showVideo ? (
            <>
              <Stack
                spacing={2}
                direction="column"
                alignContent="center"
                sx={{
                  textAlign: "center",
                }}
              >
                <div>
                  <WarningIcon htmlColor="#faad14" sx={{ fontSize: 60 }} />
                </div>
                <div style={{ fontSize: "1.5em" }}>
                  {t("Sorry that was incorrect.")}
                </div>
                <div>
                  {t("Please review the video for the correct answer.")}
                </div>

                <div className="btns">
                  <Button
                    variant="primary"
                    disabled={isDisabledToContinue}
                    onClick={() => showVideoError()}
                  >
                    {t("Review video")}
                  </Button>
                </div>
              </Stack>
            </>
          ) : (
            <div className="videoComponent">
              <div className="videoUI">
                <VideoComponent
                  questionComponentAction={showVideoError}
                  data={{
                    unit: { 
                      video: props.data.unit.incorrect.video,
                      start_on: props.data.unit.incorrect.start_on,
                      stop_on: props.data.unit.incorrect.stop_on,
                    },
                    id: 0,
                  }}
                  video_link_id={video_link_id}
                  isShownFromQuestionComponent={true}
                  schemaVersion={schemaVersion}
                  videoAlias={videoAlias}
                  studentId={studentId}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionComponent;
