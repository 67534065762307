import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
// import Amplify from "aws-amplify";
import configDev from "./cognito-config-dev";
import configUat from "./cognito-config-uat";
import configPrd from "./cognito-config-prd";
import * as serviceWorker from "./serviceWorker";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

// require("dotenv").config();

const theme = createTheme({});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      EN: {
        translation: {},
      },
      ES: {
        translation: {
          "Congratulations on completing your training! It is now time to take your final exam.":
            "¡Enhorabuena por completar tu entrenamiento! Ahora es el momento de tomar su examen final.",
          "Course Outline": "Contenido del Curso",
          Continue: "Continuar",
          "Skip and Continue": "Saltar y Continuar",
          Submit: "Enviar",
          Next: "Siguiente",
          "Review video": "Revisar video",
          "Good job, that is correct!": "Buen trabajo, respuesta correcta!",
          "Good job!": "Buen trabajo!",
          Success: "Correcto",
          "Sorry that was incorrect.": "Oh no! su respuesta es incorrecta",
          "Please review the video for the correct answer.":
            "Porfavor revisa el video para una respuesta correcta",
          "Ok, return to the Question": "Listo, regresar a la Pregunta",
          "Link your account": "Enlaza tu cuenta",
          "Knowledge Check Complete": "Verificación de conocimientos completa",
          linkToMYpa1:
            "Vincular su cuenta de PIÑA permite que los registros de sus estudiantes fluyan directamente a sus registros de estudiantes de PIÑA. Es un proceso simple, vaya a ",
          linkToMYpa2: "AQUÍ",
          linkToMYpa3:
            " y use la información a continuación para completar el registro.",
          "Skip intro": "Saltar intro",
          Finish: "Finalizar",
          "Ok, Check as completed": "Ok, marcar como completado",
          "Begin Course": "Comenzar el curso",
        },
      },
    },
    lng: "EN",
    fallbackLng: "EN",

    interpolation: {
      escapeValue: false,
    },
  });

if (
  process.env.REACT_APP_ENV_TARGET === "prd" ||
  process.env.REACT_APP_ENV_TARGET === "uat"
) {
  Sentry.init({
    dsn: "https://c807a7e4f5f041d380844dd97172a5d0@o476522.ingest.sentry.io/5525863",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV_TARGET,
    tracesSampleRate: 0.2,
    release: `pacman-ui@${process.env.REACT_APP_ENV_TARGET}`,
  });
}

// console.debug("process.env");
// console.debug(process.env);
const config =
  process.env.REACT_APP_ENV_TARGET === "prd"
    ? configPrd
    : process.env.REACT_APP_ENV_TARGET === "uat"
    ? configUat
    : configDev;

// Amplify.configure({
//   Auth: {
//     mandatorySignIn: true,
//     region: config.cognito.REGION,
//     userPoolId: config.cognito.USER_POOL_ID,
//     userPoolWebClientId: config.cognito.APP_CLIENT_ID
//   }
// });

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
