import React from "react";
import { Button } from "../../common";
import i18n from "i18next";
import "./style.scss";

const DocumentsComponent = (props) => {
  const { updateCurrentUnit, jumpToNextUnit, data: { unit: { completed } } } = props;
  const { unit } = props.data;

  return (
    <div className='resourcesContainer'>
      <span style={{color: 'white'}}>
        Documents:
      </span>
      <div style={{marginBottom: '20px', marginLeft: '10px'}}>
        <ul>
          {unit.documents.map((u, i) => (
           <li key={i}>
             {u.title[i18n.language]}
             <a 
                href={u.url[i18n.language]}
                target="_blank"
                rel="noopener noreferrer"
                style={{fontStyle: 'oblique', marginLeft: '10px'}} 
              >
                  (open external <i className="fas fa-external-link-alt"></i>)
              </a>
            </li> 
          ))}
        </ul>
      </div>
      
      <div className='btns'>
          { completed ?
              <Button
                  variant='primary'                                    
                  onClick={() => jumpToNextUnit(props.data.id)}>                                
                  Continue
              </Button> 
              :
              <Button
                  variant='primary'
                  onClick={() => updateCurrentUnit(props.data.id)}>
                  Continue
              </Button>        
          }        
      </div>
    </div>
  )
}

export default DocumentsComponent;
