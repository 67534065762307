import React from 'react';
import './styles.scss';

const Button = (props) => {
  return (
    <button 
      className={`btn ${props.variant}`} 
      {...props}
    >
      {props.children}
    </button>
  )
};

export default Button;
