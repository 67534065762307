const convertTimestampToSeconds = (value) => {
    if (value) {
      let multiplier = 1;
      let length = 0;
      value
        .toString()
        .split(":")
        .reverse()
        .forEach((v, i, a) => {
          length += parseInt(v.split(".")[0]) * multiplier;
          multiplier = multiplier * 60;
        });
      return length;
    }
  };

const urlForVideoUnit = (tenantId, unit, language, allowProgress, schemaVersion, videoAlias, studentId) => {
    const videoId = unit.video[language]['id'];

    let embedUrl;
    if ('embed_url' in unit.video[language]) {
      embedUrl = unit.video[language]['embed_url']
    } else {
      embedUrl = `https://watch.pineappleacademy.com/embed/${tenantId}/${videoId}`
    }

    const params = [`v=${schemaVersion??2}`];
    if (videoAlias){
      params.push(`alias=${videoAlias}`);
    }
    if (studentId){
      params.push(`student_id=${studentId}`);
    }
    if (allowProgress){
        params.push('progress=1');
    }else{
        params.push('progress=0');
    }
    const startSecond = unit.start_on ? convertTimestampToSeconds(unit.start_on[language]) : null;
    if (startSecond){
      params.push(`startSecond=${startSecond}`)
    }
    const endSecond = unit.stop_on ? convertTimestampToSeconds(unit.stop_on[language]) : null;
    if (endSecond && parseInt(endSecond) > 0){
      params.push(`endSecond=${endSecond}`)
    }

    return `${embedUrl}?${params.join("&")}`
  };

export {
    convertTimestampToSeconds,
    urlForVideoUnit
};