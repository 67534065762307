import React from 'react';

const SentryDebugComponent = () => {
    const methodDoesNotExist= () => {
      throw new Error('you are an error');
    }
    return (
        <div>
            <button onClick={methodDoesNotExist}>Break the world</button>
        </div>
    );
};

export default SentryDebugComponent;