import React, { useState, useEffect } from "react";
import { Button } from "../../common";
import i18n from "i18next";
import "./style.scss";

const DocumentComponent = (props) => {
  const { updateCurrentUnit, jumpToNextUnit, data: { unit: { completed } } } = props;
  const { unit } = props.data;
  
  return (
    <div className='resourcesContainer'>
      <span style={{color: 'white'}}>
        {unit.title[i18n.language]}        
        <a 
          href={unit.url[i18n.language]}
          rel="noopener noreferrer"
          target="_blank"
          style={{fontStyle: 'oblique', marginLeft: '10px'}} 
        >
          (open external <i className="fas fa-external-link-alt"></i>)
        </a>                  
      </span>
      <iframe 
        style={{width: '100%', height: '400px'}}
        src={unit.url[i18n.language]}
      />
      <div className='btns'>
          { completed ?
              <Button
                  variant='primary'                                    
                  onClick={() => jumpToNextUnit(props.data.id)}>                                
                  Continue
              </Button> 
              :
              <Button
                  variant='primary'
                  onClick={() => updateCurrentUnit(props.data.id)}>
                  Continue
              </Button>        
          }        
      </div>
    </div>
  )
}

export default DocumentComponent;
